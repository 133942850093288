import { Fragment, useContext, useEffect, useRef, useState } from 'react';
//API
import { getShoot, getShootsFav } from 'api/shoots';
import { postCounter } from 'api/counters';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useQuery } from 'react-query';

//COMPONENTS
import Button from 'components/common/Button';
//import ForumSearchBarV2 from 'containers/searchBar/ForumSearchBarV2';
import Modal from 'components/common/Modal';
import OptionsMenu from 'components/common/OptionsMenu';
import PlayerVideo from 'components/common/PlayerVideo';
import SideNav from 'components/common/nav/SideNav';
import Tabs from 'components/tabs/Tabs';
import TabsShoot from 'components/tabs/TabsShoot';
import SEO from 'components/Seo';

//CONTAINERS
import AnswerForm from 'containers/answers/AnswerForm';
import Answers from 'containers/answers/Answers';
import CardProfilShoot from 'containers/profils/CardProfilShoot';
import CardShootMobile from 'containers/shoots/CardShootMobile';
import CardShoot from 'containers/shoots/CardShoot';

//CONTEXTS
// eslint-disable-next-line no-unused-vars
import { AuthContext } from 'context/authContext';

//HOOKS
import useHasCounter from 'hooks/useHasCounter';
import useInfiniteShoots from 'hooks/useInfiniteShoots';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

//ICONS
import { ArrowDownIcon, ArrowUpIcon, BookmarkIcon, DotsVerticalIcon, EyeIcon, HeartIcon, XCircleIcon } from '@heroicons/react/solid';

//LAYOUT
import ShootLayout from 'pages/layouts/Shoot';
// eslint-disable-next-line no-unused-vars

//UTILS
import config from 'utils/constants';
import { isMobile } from 'utils/functions';
import { PageContentFadingAnimation } from 'utils/animations';

function ListingShoots() {
    //STATES
    const [authState] = useContext(AuthContext);
    //HOOKS
    //const scrollDirection = useScroll();
    const swiperContainerRef = useRef(null);
    /*  const [mainNavHeight, setMainNavHeight] = useState(0); */

    const [open, setOpen] = useState(false);
    const [currentShoot, setCurrentShoot] = useState({});
    const [playedShoots, setPlayedShoots] = useState({});

    const [refetch, setRefetch] = useState(false);
    const [infiniteFavShoots, setInfiniteFavShoots] = useState(null);
    const currentIndex = useRef(null);
    const shootRef = useRef(null);

    const forMeSwiperRef = useRef(null);
    const homeSwiperRef = useRef(null);
    const favSwiperRef = useRef(null);

    // État pour stocker si le glissement est actuellement autorisé
    const handleSlideChange = (swiperRef) => {
        if (swiperRef.current) {
            const swiper = swiperRef.current.swiper;

            // Désactiver le swipe.
            swiper.allowTouchMove = false;
            // Réactiver le swipe après un certain délai.
            const delay = 600; // en ms
            setTimeout(() => {
                swiper.allowTouchMove = true;
            }, delay);
        }
    };

    const [fetchedTabs, setFetchedTabs] = useState({});
    const isTabFetched = (tabKey) => !!fetchedTabs[tabKey];

    const infiniteShootsForMe = useInfiniteShoots(true, false, isTabFetched('tab_forMe'));
    const infiniteShootsForMeNews = useInfiniteShoots(true, true, isTabFetched('tab_forMe'));

    const infiniteShoots = useInfiniteShoots(false, false);
    const infiniteShootsNew = useInfiniteShoots(false, true);

    const [favShoots, setFavShoots] = useState([]);
    const [favShootsRefetch, setFavShootsRefetch] = useState(false);

    const handleTabChange = (index) => {
        if (index && !fetchedTabs[index]) {
            setFetchedTabs((prev) => ({ ...prev, [index]: true }));
        }
    };

    /*  function handleFetchNextPage() {
        setFetchNextPage(!fetchNextPage);
    } */

    function handleFavsShootsRefetch() {
        setFavShootsRefetch(!favShootsRefetch);
    }

    const handleTooglePlay = (shootId) => {
        setPlayedShoots((prevPlayedShoots) => {
            const newPlayedShoots = { ...prevPlayedShoots };
            newPlayedShoots[shootId] = true;
            for (const id in newPlayedShoots) {
                if (id !== shootId) {
                    newPlayedShoots[id] = false;
                }
            }
            return newPlayedShoots;
        });
    };

    function handlePrev() {
        currentIndex.current = favShoots.findIndex((favShoot) => favShoot._id === currentShoot._id);
        let prevIndex = favShoots[currentIndex.current - 1] ? currentIndex.current - 1 : favShoots.length - 1;

        setCurrentShoot(favShoots[prevIndex]);
        handleTooglePlay(favShoots[prevIndex]._id);
    }

    function handleNext() {
        currentIndex.current = favShoots.findIndex((favShoot) => favShoot._id === currentShoot._id);
        let nextIndex = favShoots[currentIndex.current + 1] ? currentIndex.current + 1 : 0;

        setCurrentShoot(favShoots[nextIndex]);
    }

    function handleOptionsShootMenu(shoot) {
        closeOptionsMenu(shoot);
        document.getElementById(`optionsMenu_${shoot._id}`).classList.toggle('hidden');
    }

    function closeOptionsMenu(shoot) {
        const els = document.getElementsByClassName('optionsMenu');
        [].forEach.call(els, function (el) {
            if (el.id !== `optionsMenu_${shoot._id}`) {
                el.classList.add('hidden');
            }
        });
    }

    function handleClick(e, shoot) {
        e.stopPropagation();
        setOpen(true);
        currentIndex.current = favShoots.findIndex((favShoot) => favShoot._id === shoot._id);
        window.history.replaceState(null, null, `/shoots/${shoot.slug}`);
        setCurrentShoot(shoot);
        handleTooglePlay(shoot._id);
    }

    function handleClose(e) {
        e.stopPropagation();
        setOpen(false);
        window.history.replaceState(null, null, `/shoots/favoris`);
    }

    useIntersectionObserver({
        target: shootRef,
        onIntersect: (isVisible) => {
            if (isVisible) {
                window.history.replaceState(null, null, `/shoots/${currentShoot.slug}`);
            }
        },
        enabled: true,
    });

    async function getFavsShoots() {
        let getFavShoots = getShootsFav();
        let promise = await getFavShoots.then((res) => {
            const shoots = (
                <>
                    {res.length > 0 ? (
                        <div>
                            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                                {res.map((shoot, index) => (
                                    <div key={index} className="relative h-64 py-4 p-2 mb-4" onClick={(e) => handleClick(e, shoot)}>
                                        <div className="h-64 w-auto rounded-lg shadow overflow-hidden">
                                            <PlayerVideo
                                                id={shoot._id}
                                                width={'100%'}
                                                shoot={shoot}
                                                muted={false}
                                                controls={false}
                                                light={`${config.serverUrl}/downloads/videos/${shoot.user.id}/screenshot_${shoot.video._id}_${shoot.selectedScreen}.jpg`}
                                            />
                                            <div className="absolute top-5 right-2">
                                                <div className="flex flex-col gap-1 items-center">
                                                    <Button
                                                        onClick={() => {
                                                            handleOptionsShootMenu(shoot);
                                                        }}
                                                        label={<DotsVerticalIcon className="w-6 h-6" />}
                                                        justIcon={true}
                                                        css="text-white"
                                                    />
                                                    <OptionsMenu shoot={shoot} user={shoot.user} path="profil" type="shoot" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col items-center justify-center h-full mt-4">
                            <p className="text-sm text-gray-500">Vous n'avez aucun bookmark</p>
                        </div>
                    )}
                </>
            );
            const shootsMobile = (
                <>
                    {res.length > 0 ? (
                        <>
                            {res.map((shoot, index) => (
                                <Fragment key={index}>
                                    {!isMobile() ? (
                                        <CardShoot shoot={shoot} refetch={handleFavsShootsRefetch} />
                                    ) : (
                                        <SwiperSlide key={`${index}_${shoot._id}`}>
                                            <CardShootMobile shoot={shoot} refetch={handleFavsShootsRefetch} />
                                        </SwiperSlide>
                                    )}
                                </Fragment>
                            ))}
                        </>
                    ) : (
                        <div className="flex flex-col items-center justify-center h-full mt-4">
                            <p className="text-sm text-gray-500">Vous n'avez aucun bookmark</p>
                        </div>
                    )}
                </>
            );
            isMobile() ? setInfiniteFavShoots(shootsMobile) : setInfiniteFavShoots(shoots);
            return res;
        });
        return promise;
    }

    const shoot = useQuery(
        ['shoot', currentShoot?.slug],
        (params) => {
            return getShoot(currentShoot?.slug);
        },
        { enabled: currentShoot?.slug ? true : false, staleTime: 0 }
    );

    const hasCounter = useHasCounter(shoot.data?.current?.counters, 'like_shoot', shoot.data?.current._id);
    const hasBookmark = useHasCounter(shoot.data?.current?.counters, 'bookmark_shoot', shoot.data?.current._id);

    const likeCounterCount = shoot.data?.current?.counters?.filter((counter) => counter.type === 'like_shoot').length;
    const bookmarkCounterCount = shoot.data?.current?.counters?.filter((counter) => counter.type === 'bookmark_shoot').length;

    function handleSubscribe() {
        if (authState.isLogged) {
            postCounter({ userTo: shoot.data?.current?.user.id, type: 'like_shoot', to: shoot.data?.current?._id }).then(() => {
                shoot.refetch();
                /*   props.refetch(); */
            });
        } else document.getElementById('button-login')?.click();
    }

    function handleBookmark() {
        if (authState.isLogged) {
            postCounter({ userTo: shoot.data?.current?.user.id, type: 'bookmark_shoot', to: shoot.data?.current?._id }).then(() => {
                shoot.refetch();
            });
        } else document.getElementById('button-login')?.click();
    }

    useEffect(() => {
        if (authState.isLogged) {
            let favShoots = getFavsShoots();
            let promise = Promise.resolve(favShoots);
            promise.then((res) => {
                setFavShoots(res);
            });
            if (currentShoot?.slug) {
                shoot.refetch();
            }
        }
        //eslint-disable-next-line
    }, [authState.isLogged, refetch, currentShoot, infiniteShoots, infiniteShootsForMe, infiniteShootsForMeNews, infiniteShootsNew]);

    //get current shoot from url
    useEffect(() => {
        const slug = window.location.pathname.split('/')[2];
        if (slug) {
            getShoot(slug).then((res) => {
                setCurrentShoot(res.current);
                currentIndex.current = res.current._id;
                if (res.current) {
                    const shootId = document.getElementById('shoot_' + res.current._id);
                    if (shootId) {
                        shootId.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                }
            });
        }
    }, []);

    const tabForMe = (
        <>
            {infiniteShootsForMeNews ? infiniteShootsForMeNews : infiniteShootsNew}
            {infiniteShootsForMe ? infiniteShootsForMe : infiniteShoots}
        </>
    );
    const tabHome = (
        <>
            {infiniteShootsNew}
            {infiniteShoots}
        </>
    );

    const tabForMeMobile = (
        <>
            {/* TROUVER UNE SOLUTION POUR REGROUPER LES DEUX LISTINGS DANS UNE SEULE PAGINATION */}
            <Swiper
                ref={forMeSwiperRef}
                style={{ height: '100%' }}
                direction="vertical"
                slidesPerView="1"
                mousewheel={true}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                autoHeight={true}
                onSlideChange={() => handleSlideChange(forMeSwiperRef)}
                onReachEnd={() => {}}
            >
                {infiniteShootsForMeNews ? infiniteShootsForMeNews : infiniteShootsNew}
                {infiniteShootsForMe ? infiniteShootsForMe : infiniteShoots}
            </Swiper>

            {/*  {(infiniteShootsForMeNews ? infiniteShootsForMeNews : infiniteShootsNew) && (infiniteShootsForMe ? infiniteShootsForMe : infiniteShoots)} */}
        </>
    );
    const tabHomeMobile = (
        <>
            {/* TROUVER UNE SOLUTION POUR REGROUPER LES DEUX LISTINGS DANS UNE SEULE PAGINATION */}
            {/*   {infiniteShootsNew} */}
            <Swiper
                ref={homeSwiperRef}
                style={{ height: '100%' }}
                direction="vertical"
                slidesPerView="1"
                mousewheel={true}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                autoHeight={true}
                onSlideChange={() => handleSlideChange(homeSwiperRef)}
                onReachEnd={() => {}}
            >
                {infiniteShootsNew}
                {infiniteShoots}
            </Swiper>
        </>
    );
    const tabFav = (
        <>
            <Swiper
                ref={favSwiperRef}
                style={{ height: '100%' }}
                direction="vertical"
                slidesPerView="1"
                mousewheel={true}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                autoHeight={true}
                onSlideChange={() => handleSlideChange(favSwiperRef)}
                onReachEnd={() => {}}
            >
                {infiniteFavShoots}
            </Swiper>
        </>
    );

    const tabs = [];
    tabs['desktop'] = [
        { label: 'Découverte', key: 'tab_home', content: tabHome },
        { label: 'Pour moi', key: 'tab_forMe', content: tabForMe, visible: authState.isLogged },
        { label: 'Favoris', key: 'tab_favoris', content: tabFav, visible: authState.isLogged, url: '/shoots/favoris' },
    ];

    tabs['mobile'] = [
        { label: 'Découverte', key: 'tab_home', content: tabHomeMobile },
        /*  { label: 'Pour moi', key: 'tab_forMe', content: tabForMeMobile, visible: authState.isLogged },
        { label: 'Favoris', key: 'tab_favorites', content: tabFav, visible: authState.isLogged, url: '/shoots/favoris' }, */
    ];

    function refetchFav() {
        setRefetch(!refetch);
    }

    useEffect(() => {
        const setViewportHeight = () => {
            // Calculer la valeur de 1vh basé sur la hauteur visible du viewport
            document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
        };

        // Définir la hauteur initiale
        setViewportHeight();

        // Ajouter un écouteur pour mettre à jour la hauteur à chaque redimensionnement
        window.addEventListener('resize', setViewportHeight);

        // Nettoyer l'écouteur au démontage du composant
        return () => {
            window.removeEventListener('resize', setViewportHeight);
        };
    }, []);

    return (
        <ShootLayout>
            <Modal
                modalWidth={'w-screen h-screen overflow-y-hidden sm:overflow-y-auto lg:overflow-y-hidden relative'}
                open={open}
                title={false}
                fullScreen={true}
                noMaxWidth={true}
            >
                <div className="w-full ">
                    <div className="w-full h-full sm:flex sm:flex-col lg:flex-row">
                        <div className={`relative lg:w-screen lg:max-w-full bg-black`}>
                            <div ref={shootRef} className={`relative ${currentShoot.video?.position === 'landscape' ? 'h-screen w-auto' : 'h-screen w-auto'}`}>
                                <PlayerVideo
                                    id={currentShoot._id}
                                    width={'100%'}
                                    videoFitCover={false}
                                    played={playedShoots[currentShoot._id]}
                                    shoot={currentShoot}
                                    muted={false}
                                    controls={true}
                                />
                                <div className="absolute top-2 left-5 flex flex-col gap-10">
                                    <div className="flex flex-col gap-1 items-center">
                                        <Button label={<XCircleIcon className="w-10 h-10 text-white" />} justIcon={true} onClick={(e) => handleClose(e)} />
                                    </div>
                                </div>
                                <div className="absolute bottom-52 right-5 flex flex-col gap-10">
                                    {shoot.status === 'success' && shoot.data.current && (
                                        <>
                                            <div className="flex flex-col gap-1 items-center">
                                                <Button
                                                    label={<EyeIcon className="w-6 h-6 mx-auto" />}
                                                    justIcon={true}
                                                    css="w-12 h-12 bg-white border border-graySkills rounded-full text-graySkills"
                                                />
                                                <span className="text-xs text-graySkills">{shoot.data.current?.views}</span>
                                            </div>
                                            <div className="flex flex-col gap-1 items-center">
                                                <Button
                                                    label={<HeartIcon className="w-6 h-6 mx-auto" />}
                                                    justIcon={true}
                                                    css={`w-12 h-12 border rounded-full ${
                                                        hasCounter ? 'text-white border-red bg-red' : 'text-graySkills border-graySkills bg-white'
                                                    }`}
                                                    // css="w-12 h-12 bg-white border border-graySkills rounded-full text-graySkills"
                                                    onClick={() => handleSubscribe(shoot.data.current)}
                                                />
                                                <span className={`text-graySkills`}>{likeCounterCount}</span>
                                            </div>
                                            <div className="flex flex-col gap-1 items-center">
                                                <Button
                                                    label={<BookmarkIcon className="w-6 h-6 mx-auto" />}
                                                    justIcon={true}
                                                    css={`w-12 h-12 border rounded-full ${
                                                        hasBookmark
                                                            ? 'text-white border-purpleSkills bg-purpleSkills'
                                                            : 'text-graySkills border-graySkills bg-white'
                                                    }`}
                                                    onClick={() => handleBookmark(shoot.data.current)}
                                                />
                                                <span className={`text-graySkills`}>{bookmarkCounterCount}</span>
                                            </div>
                                        </>
                                    )}
                                    <div className="flex flex-col gap-1 items-center">
                                        <Button
                                            label={<ArrowUpIcon className="w-6 h-6 mx-auto" />}
                                            justIcon={true}
                                            css="w-12 h-12 bg-white border border-graySkills rounded-full text-graySkills"
                                            onClick={() => handlePrev()}
                                        />
                                    </div>
                                    <div className="flex flex-col gap-1 items-center">
                                        <Button
                                            label={<ArrowDownIcon className="w-6 h-6 mx-auto" />}
                                            justIcon={true}
                                            css="w-12 h-12 bg-white border border-graySkills rounded-full text-graySkills"
                                            onClick={() => {
                                                handleNext();
                                                //console.log(favShoots);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`hidden relative sm:block sm:p-2 w-full lg:w-[600px] h-screen`}>
                            {shoot.status === 'success' && shoot.data.current && (
                                <div className="grid grid-auto-rows">
                                    <div className="h-full">
                                        <CardProfilShoot user={shoot.data.current.user} shoot={shoot.data.current} />
                                        <div className="px-2 py-2 w-full bg-white md:bg-transparent bottom-2 z-10">
                                            <AnswerForm
                                                item={shoot.data.current}
                                                type={'shoot'}
                                                refetch={shoot.refetch}
                                                options={{ placeholder: null, isShoot: true }}
                                            />
                                        </div>
                                    </div>
                                    {currentShoot?.answers && (
                                        <Answers item={shoot.data.current} counters={shoot.data.current?.counters} type={'shoot'} refetch={shoot.refetch} />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
            <SEO
                title={'Exprimez votre créativité professionnelle avec Skillsmarket Shoots'}
                description={
                    'Partagez votre univers professionnel de manière créative grâce à Skillsmarket Shoots. Montrez vos compétences, partagez vos expériences et faites-vous connaître par la communauté Skillsmarket.'
                }
                type="summary_large_image"
            />
            <div className="container">
                <div className="sm:grid sm:grid-cols-12 sm:gap-8 sm:pb-4">
                    {!isMobile() && (
                        <div className="hidden sm:block sm:col-span-3">
                            <SideNav />
                        </div>
                    )}
                    <div className="sm:col-span-9 lg:col-span-8">
                        <main>
                            <PageContentFadingAnimation duration={250}>
                                {!isMobile() ? (
                                    <>
                                        {authState.isLogged ? (
                                            <Tabs tabs={tabs?.desktop} refetch={refetchFav} onTabChange={handleTabChange} />
                                        ) : (
                                            <>
                                                {infiniteShootsNew}
                                                {infiniteShoots}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <div className={`fixed top-0 bottom-0 left-0 right-0 flex flex-col bg-black`} style={{ touchAction: 'none' }}>
                                            <div
                                                ref={swiperContainerRef}
                                                className="relative w-full h-full"
                                                style={{ height: 'calc(var(--vh, 1vh) * 100 - 60px)' }}
                                            >
                                                <div className="swiper-container w-full h-full">
                                                    {authState.isLogged ? <TabsShoot tabs={tabs?.mobile} /> : <>{tabHomeMobile}</>}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </PageContentFadingAnimation>
                        </main>
                    </div>
                </div>
            </div>
        </ShootLayout>
    );
}

export default ListingShoots;
