import { useEffect } from 'react';
import { createPortal } from 'react-dom';

import { useSpring, animated } from 'react-spring';

function ModalComment(props) {
    useEffect(() => {
        if (props.open) document.querySelector('body').style.overflow = 'hidden';
        else document.querySelector('body').style.overflow = 'auto';
    }, [props.open]);

    const openCloseSlideAnimationUserMenu = useSpring({
        transform: props.open ? 'translateY(0%)' : 'translateY(100%)',
        onRest: () => {
            if (!props.open) {
                /*  setModalComment(false); */
                props.setOpen(false);
            }
        },
        config: {
            duration: 225,
        },
    });

    if (!props.open) return null;

    return createPortal(
        <div className="fixed bottom-0 left-0 w-full">
            <div
                className="absolute bottom-0 w-screen h-screen bg-graySkills -z-10 opacity-20"
                onClick={(e) => {
                    e?.preventDefault();
                    props.setOpen(false);
                }}
            />
            <animated.div style={openCloseSlideAnimationUserMenu} className="relative bg-white rounded-t-xl w-full h-[500px] overflow-y-auto">
                <section>{props.children}</section>
            </animated.div>
        </div>,
        document.body
    );
}

export default ModalComment;
