import { useEffect, useState } from 'react';

//LIBS
import { Link, useNavigate } from 'react-router-dom';

//UTILS
import config from 'utils/constants';

//API
import { archiveAllNotifications } from 'api/notifications';

//COMPONENTS
import Moment from 'components/common/Moment';
import Button from 'components/common/Button';
import Avatar from 'containers/profils/Avatar';

//CONFIG
import { DotsHorizontalIcon, PlayIcon } from '@heroicons/react/solid';

function CardNotification({ notification, refetch, socket }) {
    const [item, setItem] = useState(notification);

    useEffect(() => {
        setItem(notification);
        /*  console.log(item); */
        //eslint-disable-next-line
    }, [, notification]);

    const navigate = useNavigate();

    const messages = {
        like: {
            text: (id, item) => {
                return 'a aimé votre profil';
            },
            to: (id, item) => {
                return `/profil/${item.userFrom?.profile?.slug}`;
            },
            button: {
                label: 'Voir le profil',
                action: (id, item) => {
                    navigate(`/profil/${item.userFrom?.profile?.slug}`);
                },
            },
        },
        like_portfolio: {
            text: (id, item) => {
                return 'a aimé une image de votre portfolio';
            },
            to: (id, item) => {
                return `/profil/${item.userFrom?.profile?.slug}`;
            },
            button: {
                label: 'Voir le profil',
                action: (id, item) => {
                    navigate(`/profil/${item.userFrom?.profile?.slug}`);
                },
            },
        },
        like_service: {
            text: (id, item) => {
                return "a aimé l'un de vos services";
            },
            to: (id, item) => {
                return `/profil/${item.userFrom?.profile?.slug}`;
            },
            button: {
                label: 'Voir le profil',
                action: (id, item) => {
                    navigate(`/profil/${item.userFrom?.profile?.slug}`);
                },
            },
        },
        like_shoot: {
            text: (id, item) => {
                return 'a liké votre shoot';
            },
            to: (id, item) => {
                return `/profil/${item.userFrom?.profile?.slug}`;
            },
            posterShootUrl: (id, item) => {
                if (item?.infos) {
                    const parsedInfos = JSON.parse(item.infos);
                    return `${config.serverUrl}${parsedInfos?.poster}`;
                } else {
                    return false;
                }
            },
            button: {
                label: 'Voir le shoot',
                action: (id, item) => {
                    if (item?.infos) {
                        const parsedInfos = JSON.parse(item.infos);
                        // handleOk(id);
                        navigate(`/shoots/${parsedInfos?.slugShoot}`);
                    }
                },
            },
        },
        bookmark_shoot: {
            text: (id, item) => {
                return 'a mis votre shoot en favoris';
            },
            to: (id, item) => {
                return `/profil/${item.userFrom?.profile?.slug}`;
            },
            posterShootUrl: (id, item) => {
                if (item?.infos) {
                    const parsedInfos = JSON.parse(item.infos);
                    return `${config.serverUrl}${parsedInfos?.poster}`;
                } else {
                    return false;
                }
            },
            button: {
                label: 'Voir le shoot',
                action: (id, item) => {
                    if (item?.infos) {
                        const parsedInfos = JSON.parse(item.infos);
                        // handleOk(id);
                        navigate(`/shoots/${parsedInfos?.slugShoot}`);
                    }
                },
            },
        },
        like_answer: {
            text: (id, item) => {
                return 'a aimé votre réponse';
            },
            to: (id, item) => {
                if (item?.infos) {
                    const parsedInfos = JSON.parse(item.infos);
                    // handleOk(id);
                    return `/room/${parsedInfos?.url}`;
                }
            },
            button: {
                label: 'Voir la question',
                action: (id, item) => {
                    const parsedInfos = JSON.parse(item.infos);
                    // handleOk(id);
                    navigate(`/room/${parsedInfos?.url}`);
                },
            },
        },
        like_answerTo: {
            text: (id, item) => {
                return 'a aimé votre réponse';
            },
            to: (id, item) => {
                if (item?.infos) {
                    const parsedInfos = JSON.parse(item.infos);
                    // handleOk(id);
                    return `/room/${parsedInfos?.url}`;
                }
            },
            button: {
                label: 'Voir la room',
                action: (id, notification) => {
                    const parsedInfos = JSON.parse(notification.infos);
                    // handleOk(id);
                    navigate(`/room/${parsedInfos?.url}`);
                },
            },
        },
        select_answer: {
            text: (id, item) => {
                return 'a sélectionné votre réponse';
            },
            to: (id, item) => {
                if (item?.infos) {
                    const parsedInfos = JSON.parse(item.infos);
                    // handleOk(id);
                    return `/room/${parsedInfos?.url}`;
                }
            },
            button: {
                label: 'Voir la room',
                action: (id, notification) => {
                    const parsedInfos = JSON.parse(notification.infos);
                    // handleOk(id);
                    navigate(`/room/${parsedInfos?.url}`);
                },
            },
        },
        subscription: {
            text: (id, item) => {
                return "s'est abonné à vous";
            },
            // button: {
            //     label: "S'abonner en retour",
            //     // action: () => {},
            // },
            to: (id, item) => {
                return `/profil/${item.userFrom?.profile?.slug}`;
            },
            button: {
                label: 'Voir le profil',
                action: (id, item) => {
                    navigate(`/profil/${item.userFrom?.profile?.slug}`);
                },
            },
        },
        visioInit: {
            text: (id, item) => {
                return 'souhaite prendre RDV avec vous';
            },
            to: (id, item) => {
                return '/dashboard/visios/asked';
            },
            button: {
                label: 'Voir la demande',
                action: (id) => {
                    // handleOk(id);
                    navigate('/dashboard/visios/asked');
                },
            },
        },
        visioCanceled: {
            text: (id, item) => {
                return 'a refusé votre demande de visio';
            },
            to: () => {
                return false;
            },
        },
        visioAccepted: {
            text: (id, item) => {
                return 'a accepté votre demande de visio !';
            },
            to: (id, item) => {
                return '/dashboard/visios/asked';
            },
            button: {
                label: 'Voir la demande',
                action: (id) => {
                    // handleOk(id);
                    navigate('/dashboard/visios/asked');
                },
            },
        },
        visioValidated: {
            text: (id, item) => {
                return 'a validé la visio !';
            },
            to: (id, item) => {
                return '/dashboard/visios/asked';
            },
            button: {
                label: 'Voir la visio',
                action: (id) => {
                    // handleOk(id);
                    navigate('/dashboard/visios/asked');
                },
            },
        },
        subscribe_room: {
            text: (id, item) => {
                return "s'est abonné à votre room";
            },
            to: (id, item) => {
                if (item?.infos) {
                    const parsedInfos = JSON.parse(item.infos);
                    // handleOk(id);
                    return `/room/${parsedInfos.url}`;
                }
            },
            button: {
                label: 'Voir la room',
                action: (id, item) => {
                    if (item?.infos) {
                        const parsedInfos = JSON.parse(item.infos);
                        // handleOk(id);
                        navigate(`/room/${parsedInfos.url}`);
                    }
                },
            },
        },
        room_created_subscribed: {
            text: (id, item) => {
                return 'a publié une nouvelle room qui peut vous intéresser';
            },
            to: (id, item) => {
                if (item?.infos) {
                    const parsedInfos = JSON.parse(item.infos);
                    // handleOk(id);
                    return `/room/${parsedInfos.slugRoom}`;
                }
            },
            button: {
                label: 'Voir la room',
                action: (id, notification) => {
                    if (notification?.infos) {
                        const parsedInfos = JSON.parse(notification.infos);
                        // handleOk(id);
                        navigate(`/room/${parsedInfos.slugRoom}`);
                    }
                },
            },
        },
        room_created_hasTags: {
            text: (id, item) => {
                return 'a publié une nouvelle room qui peut vous intéresser';
            },
            to: (id, item) => {
                if (item?.infos) {
                    const parsedInfos = JSON.parse(item.infos);
                    // handleOk(id);
                    return `/room/${parsedInfos.slugRoom}`;
                }
            },
            button: {
                label: 'Voir la room',
                action: (id, notification) => {
                    if (notification?.infos) {
                        const parsedInfos = JSON.parse(notification.infos);
                        // handleOk(id);
                        navigate(`/room/${parsedInfos.slugRoom}`);
                    }
                },
            },
        },
        room_created_admin: {
            text: (id, item) => {
                if (item?.infos) {
                    const parsedInfos = JSON.parse(item.infos);

                    if (parsedInfos.typeRoom === 'news') {
                        return 'votre post est publié';
                    } else if (parsedInfos.typeRoom === 'question') {
                        return 'votre question est publiée';
                    } else if (parsedInfos.typeRoom === 'job') {
                        return "votre offre d'emploi est publiée";
                    } else if (parsedInfos.typeRoom === 'private') {
                        return 'votre room privée est publiée';
                    } else {
                        return 'votre room est publiée';
                    }
                } else {
                    return 'votre room est publiée';
                }
            },
            to: (id, item) => {
                if (item?.infos) {
                    const parsedInfos = JSON.parse(item.infos);
                    // handleOk(id);
                    return `/room/${parsedInfos.slugRoom}`;
                }
            },
            button: {
                label: 'Voir la room',
                action: (id, item) => {
                    if (item?.infos) {
                        const parsedInfos = JSON.parse(item.infos);
                        // handleOk(id);
                        navigate(`/room/${parsedInfos.slugRoom}`);
                    }
                },
            },
        },
        room_created_anonymous_admin: {
            text: (id, item) => {
                if (item?.infos) {
                    const parsedInfos = JSON.parse(item.infos);

                    if (parsedInfos.typeRoom === 'news') {
                        return 'votre post anonyme est publié';
                    } else if (parsedInfos.typeRoom === 'question') {
                        return 'votre question anonyme est publiée';
                    } else {
                        return 'votre room anonyme est publiée';
                    }
                } else {
                    return 'votre room anonyme est publiée';
                }
            },
            to: (id, item) => {
                if (item?.infos) {
                    const parsedInfos = JSON.parse(item.infos);
                    // handleOk(id);
                    return `/room/${parsedInfos.slugRoom}`;
                }
            },
            button: {
                label: 'Voir la room',
                action: (id, item) => {
                    if (item?.infos) {
                        const parsedInfos = JSON.parse(item.infos);
                        // handleOk(id);
                        navigate(`/room/${parsedInfos.slugRoom}`);
                    }
                },
            },
        },
        room_created_profil: {
            text: (id, item) => {
                return 'vous a posté une question publique';
            },
            to: (id, item) => {
                if (item?.infos) {
                    const parsedInfos = JSON.parse(item.infos);
                    // handleOk(id);
                    return `/room/${parsedInfos.slugRoom}`;
                }
            },
            button: {
                label: 'Voir la room',
                action: (id, notification) => {
                    if (notification?.infos) {
                        const parsedInfos = JSON.parse(notification.infos);
                        // handleOk(id);
                        navigate(`/room/${parsedInfos.slugRoom}`);
                    }
                },
            },
        },
        room_created_anonymous: {
            text: (id, item) => {
                return 'Un utilisateur a posté une nouvelle question qui peut vous intéresser';
            },
            to: (id, item) => {
                if (item?.infos) {
                    const parsedInfos = JSON.parse(item.infos);
                    // handleOk(id);
                    return `/room/${parsedInfos.slugRoom}`;
                }
            },
            button: {
                label: 'Voir la room',
                action: (id, notification) => {
                    if (notification?.infos) {
                        const parsedInfos = JSON.parse(notification.infos);
                        // handleOk(id);
                        navigate(`/room/${parsedInfos.slugRoom}`);
                    }
                },
            },
        },
        send_profil_message: {
            text: (id, item) => {
                return 'vous a envoyé un message par email';
            },
            to: (id, item) => {
                return `/profil/${item.userFrom?.profile?.slug}`;
            },
            button: {
                label: 'Voir le profil',
                action: (id, item) => {
                    navigate(`/profil/${item.userFrom?.profile?.slug}`);
                },
            },
        },
        answer: {
            text: (id, item) => {
                return ' a répondu à une room à laquelle vous êtes abonné';
            },
            to: (id, item) => {
                if (item?.infos) {
                    const parsedInfos = JSON.parse(item.infos);
                    // handleOk(id);
                    return `/room/${parsedInfos.slugRoom}`;
                }
            },
            button: {
                label: 'Voir la réponse',
                action: (id, item) => {
                    if (item?.infos) {
                        const parsedInfos = JSON.parse(item.infos);
                        navigate(`/room/${parsedInfos.slugRoom}`);
                    }
                },
            },
        },
        answer_shoot: {
            text: (id, item) => {
                return ' a répondu à votre commentaire';
            },
            to: (id, item) => {
                if (item?.infos) {
                    const parsedInfos = JSON.parse(item.infos);
                    // handleOk(id);
                    return `/shoots/${parsedInfos.slugShoot}`;
                }
            },
            button: {
                label: 'Voir le shoot',
                action: (id, item) => {
                    if (item?.infos) {
                        const parsedInfos = JSON.parse(item.infos);
                        navigate(`/shoots/${parsedInfos.slugShoot}`);
                    }
                },
            },
        },
        answer_admin: {
            text: (id, item) => {
                return 'a répondu à votre room';
            },
            to: (id, item) => {
                if (item?.infos) {
                    const parsedInfos = JSON.parse(item.infos);
                    // handleOk(id);
                    return `/room/${parsedInfos.slugRoom}`;
                }
            },
            button: {
                label: 'Voir la réponse',
                action: (id, item) => {
                    if (item?.infos) {
                        const parsedInfos = JSON.parse(item.infos);
                        navigate(`/room/${parsedInfos.slugRoom}`);
                    }
                },
            },
        },
        answer_admin_shoot: {
            text: (id, item) => {
                return 'a commenté votre shoot';
            },
            to: (id, item) => {
                if (item?.infos) {
                    const parsedInfos = JSON.parse(item.infos);
                    // handleOk(id);
                    return `/shoots/${parsedInfos.slugShoot}`;
                }
            },
            button: {
                label: 'Voir le shoot',
                action: (id, item) => {
                    if (item?.infos) {
                        const parsedInfos = JSON.parse(item.infos);
                        navigate(`/shoots/${parsedInfos.slugShoot}`);
                    }
                },
            },
        },
        job_apply: {
            text: (id, item) => {
                return "a postulé à votre offre d'emploi";
            },
            to: (id, item) => {
                if (item?.infos) {
                    const parsedInfos = JSON.parse(item.infos);
                    return `/room/${parsedInfos.slugRoom}`;
                }
                return false;
            },
            button: {
                label: "Voir l'offre d'emploi",
                action: (id, item) => {
                    if (item?.infos) {
                        const parsedInfos = JSON.parse(item.infos);
                        navigate(`/room/${parsedInfos.slugRoom}`);
                    }
                },
            },
        },
        shareNeed: {
            text: (id, item) => {
                return 'vous a recommandé à ' + item.sharedFromUser?.profile?.pseudo;
            },
            to: (id, item) => {
                return `/profil/${item.sharedFromUser?.profile?.slug}`;
            },
        },
    };
    /*
    function handleOk(e) {
        deleteNotification(item.id).then((data) => {
            toast('Notification supprimée', { type: 'success' });
            refetch();
            socket.send('notificationsRefresh');
        });
    } */

    function handleArchiveAll(e) {
        e?.preventDefault();
        e?.stopPropagation();
        archiveAllNotifications().then((data) => {
            refetch();
            socket.send('notificationsRefresh');
            /*    console.log('refetch'); */
            /* socket.send('notificationsRefresh'); */
        });
    }

    return (
        <>
            <div
                onClick={item.newNotif ? handleArchiveAll : undefined}
                className={`flex flex-row w-full ${item.newNotif ? 'bg-lightBlueSkills bg-opacity-50 rounded-xl' : ''} ${
                    item?.sharedFromUser && 'bg-blancSkills'
                } border-b mb-1 border-gray-100 py-4`}
            >
                <div className={`flex flex-row w-full ${item.newNotif ? 'cursor-pointer' : ''}`}>
                    {item.newNotif ? (
                        <div className="h-14 sm:h-full flex flex-col justify-center sm:my-auto px-2 sm:px-4">
                            <span
                                className="relative block rounded-full h-2 w-2 bg-blueMain"
                                style={{ boxShadow: '0px 0px 15px 2px rgba(17, 67, 244, 0.25)' }}
                            />
                        </div>
                    ) : (
                        <div className="my-auto px-2 sm:px-4">
                            <span className="relative inline-flex h-2 w-2" />
                        </div>
                    )}

                    <div className="flex justify-between w-full my-auto">
                        <div className="relative w-full">
                            <div className="flex flex-row w-full gap-2 sm:gap-4 relative">
                                <Avatar user={item.userFrom} className="w-20" />
                                <div className={`w-full flex flex-col ${item?.sharedFromUser ? 'self-center' : ''}`}>
                                    <div className="flex flex-col">
                                        {item.userFrom?.profile?.pseudo ? (
                                            <>
                                                <Link id="MainLinkNotification" to={messages[item?.type]?.to(item?.id, item)}>
                                                    <span
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            navigate(`/profil/${item.userFrom?.profile?.slug}`);
                                                        }}
                                                        className="text-sm font-bold text-purpleSkills"
                                                        alt={item.userFrom?.profile?.pseudo}
                                                    >
                                                        {item.userFrom?.profile?.pseudo}{' '}
                                                    </span>

                                                    <span className="text-sm font-semibold text-purpleSkills">
                                                        {messages[item?.type]?.text(item?.id, item)}
                                                    </span>
                                                </Link>
                                            </>
                                        ) : (
                                            <Link to={messages[item?.type]?.to(item?.id, item)}>
                                                <span className="text-sm font-bold text-purpleSkills">{messages[item?.type]?.text(item?.id, item)}</span>
                                            </Link>
                                        )}
                                        {!item?.sharedFromUser && item.userFrom?.profile?.status && (
                                            <span className="text-xs text-graySkills">{item.userFrom?.profile?.status}</span>
                                        )}
                                    </div>
                                    {item.type === 'like_shoot' && messages.like_shoot.posterShootUrl(item?.id, item) && (
                                        <div
                                            className="sm:hidden relative flex flex-col justify-center cursor-pointer w-[6rem] mt-2"
                                            onClick={(e) => {
                                                e?.preventDefault();
                                                e?.stopPropagation();

                                                if (item?.infos) {
                                                    const parsedInfos = JSON.parse(item.infos);
                                                    navigate(`/shoots/${parsedInfos?.slugShoot}`);
                                                }
                                            }}
                                        >
                                            <img
                                                className="overflow-hidden w-full h-16 sm:h-20 rounded-2xl object-cover"
                                                src={messages.like_shoot.posterShootUrl(item?.id, item)}
                                                alt={'poster shoot'}
                                            />
                                            <div
                                                className="absolute rounded-full inset-0 flex items-center justify-center"
                                                style={{ transform: 'translate3d(0, 0, 0)' }}
                                            >
                                                <PlayIcon className="w-6 h-6 text-white backdrop-blur-lg rounded-full" />
                                            </div>
                                        </div>
                                    )}
                                    {item.type === 'bookmark_shoot' && messages.bookmark_shoot.posterShootUrl(item?.id, item) && (
                                        <div
                                            className="sm:hidden relative flex flex-col justify-center cursor-pointer w-[6rem] mt-2"
                                            onClick={(e) => {
                                                e?.preventDefault();
                                                e?.stopPropagation();

                                                if (item?.infos) {
                                                    const parsedInfos = JSON.parse(item.infos);
                                                    navigate(`/shoots/${parsedInfos?.slugShoot}`);
                                                }
                                            }}
                                        >
                                            <img
                                                className="overflow-hidden w-full h-16 sm:h-20 rounded-2xl object-cover"
                                                src={messages.bookmark_shoot.posterShootUrl(item?.id, item)}
                                                alt={'poster shoot'}
                                            />
                                            <div
                                                className="absolute rounded-full inset-0 flex items-center justify-center"
                                                style={{ transform: 'translate3d(0, 0, 0)' }}
                                            >
                                                <PlayIcon className="w-6 h-6 text-white backdrop-blur-lg rounded-full" />
                                            </div>
                                        </div>
                                    )}
                                    <Link className="h-full pt-2 md:pt-0" to={messages[item?.type]?.to(item?.id, item)}>
                                        <div className="flex flex-col justify-end h-full">
                                            <div className="flex justify-start gap-2">
                                                {messages[item.type]?.button && (
                                                    <Button
                                                        label={messages[item.type].button.label}
                                                        css="link text-graySkills bg-transparent"
                                                        onClick={(e) => {
                                                            e?.preventDefault();
                                                            e?.stopPropagation();
                                                            messages[item.type].button.action(item.id, item);
                                                            item.newNotif && handleArchiveAll();
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        {item.type === 'like_shoot' && messages.like_shoot.posterShootUrl(item?.id, item) && (
                            <div
                                className="hidden sm:flex relative flex-col justify-center cursor-pointer w-[7rem] ml-2"
                                onClick={() => {
                                    if (item?.infos) {
                                        const parsedInfos = JSON.parse(item.infos);
                                        navigate(`/shoots/${parsedInfos?.slugShoot}`);
                                    }
                                }}
                            >
                                <img
                                    className="overflow-hidden w-full h-16 sm:h-20 rounded-2xl object-cover"
                                    src={messages.like_shoot.posterShootUrl(item?.id, item)}
                                    alt={'poster shoot'}
                                />
                                <div className="absolute rounded-full inset-0 flex items-center justify-center" style={{ transform: 'translate3d(0, 0, 0)' }}>
                                    <PlayIcon className="w-6 h-6 text-white backdrop-blur-lg rounded-full" />
                                </div>
                            </div>
                        )}
                        {item.type === 'bookmark_shoot' && messages.bookmark_shoot.posterShootUrl(item?.id, item) && (
                            <div
                                className="hidden sm:flex relative flex-col justify-center cursor-pointer w-[7rem] ml-2"
                                onClick={() => {
                                    if (item?.infos) {
                                        const parsedInfos = JSON.parse(item.infos);
                                        navigate(`/shoots/${parsedInfos?.slugShoot}`);
                                    }
                                }}
                            >
                                <img
                                    className="overflow-hidden w-full h-16 sm:h-20 rounded-2xl object-cover"
                                    src={messages.bookmark_shoot.posterShootUrl(item?.id, item)}
                                    alt={'poster shoot'}
                                />
                                <div className="absolute rounded-full inset-0 flex items-center justify-center" style={{ transform: 'translate3d(0, 0, 0)' }}>
                                    <PlayIcon className="w-6 h-6 text-white backdrop-blur-lg rounded-full" />
                                </div>
                            </div>
                        )}

                        <div className="flex flex-col w-32 px-2">
                            <div className="text-xs text-center text-graySkills">
                                <Moment date={item.createdAt} fromNow />
                            </div>
                            <div className="text-center my-auto">
                                <Button
                                    label={<DotsHorizontalIcon className="w-8 h-8" />}
                                    css="text-graySkills"
                                    justIcon={true}
                                    onClick={() => {
                                        return;
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardNotification;
