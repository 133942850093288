import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
//UTILS
import { useSpring, animated } from 'react-spring';
import { cutString, formatTitle } from 'utils/functions';
//API
import { getSingleRoom } from '../../api/forum';
import { postCounter } from 'api/counters';
//CONTEXT
import { AuthContext } from 'context/authContext';
import { StoreContext } from 'context/storeContext';
//HOOKS
import useHasCounter /* useGetListCounter */ from 'hooks/useHasCounter';

//COMPONENTS
import OptionsMenu from 'components/common/OptionsMenu';

//CONTAINERS
//import CardFrontService from 'containers/services/CardFrontService';
//import CardProfilRoom from 'containers/profils/CardProfilRoom';
import PoleEmploiHeadRoom from 'containers/rooms/PoleEmploiHeadRoom';
import PoleEmploiJobRoom from 'containers/rooms/PoleEmploiJobRoom';
import AnswerForm from 'containers/answers/AnswerForm';
import Answers from 'containers/answers/Answers';

//ICONS
import { DotsVerticalIcon, HeartIcon, XIcon } from '@heroicons/react/solid';
// import { ShareIcon } from '@heroicons/react/outline';
import HeadRoom from './HeadRoom';
import JobRoom from './JobRoom';
import SEO from 'components/Seo';
import ShareIcon from '../../components/svgs/Share';

function JobDetail(props) {
    const [authState] = useContext(AuthContext);
    const store = useContext(StoreContext);

    const [openedJobDetail, setOpenedJobDetail] = useState(false);
    const [currentJob, setCurrentJob] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const currentIndex = useRef(null);
    const lastSlugRef = useRef();

    const jobRoom = useQuery(
        ['room', currentJob?.slug],
        (params) => {
            return getSingleRoom(currentJob?.slug);
        },
        { enabled: currentJob?.slug ? true : false, staleTime: 0 }
    );

    const isJobApply = useHasCounter(jobRoom?.data?.counters, 'job_apply', jobRoom?.data?._id);
    const hasBookmark = useHasCounter(jobRoom.data?.counters, 'bookmark_job', jobRoom?.data?._id);
    //const listUsersJobApply = useGetListCounter(currentJob?.counters, 'job_apply', currentJob?._id);

    useEffect(() => {
        setIsAdmin(authState.isLogged && authState?.me?.id === jobRoom?.data?.user?._id ? true : false);
        //eslint-disable-next-line
    }, [authState.me, jobRoom.data]);

    function jobApply() {
        if (currentJob) {
            postCounter({ type: 'job_apply', to: jobRoom?.data?._id }).then((res) => {
                jobRoom.refetch();
                if (typeof props.refetchFav === 'function') {
                    props.refetchFav();
                }
            });
        }
    }

    function handleOpenJobDetail(job) {
        //if (e) e.preventDefault();
        store.set({ type: 'add', key: 'openJobDetail', value: { open: true, jobId: job._id } });
    }

    function closeJobDetail() {
        const currentState = window.history.state;

        if (currentState && currentState.originalUrl) {
            window.history.replaceState({}, '', currentState.originalUrl);
        }
        setOpenedJobDetail(false);
        store.set({ type: 'del', key: 'openJobDetail' });
    }

    const handleStateChange = useCallback(() => {
        const slug = window.location.pathname.split('/')[2];

        if (slug) {
            lastSlugRef.current = slug;
            getSingleRoom(slug)
                .then((res) => {
                    if (res && res._id) {
                        setCurrentJob(res);
                        currentIndex.current = res._id;
                        /* setOpenedJobDetail(true); */
                        handleOpenJobDetail(res);
                    } else {
                        console.error('Données du job non trouvées ou mal formatées');
                    }
                })
                .catch((error) => {
                    console.error('Erreur lors de la récupération du job:', error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //DETECT URL CHANGE FOR JOB
    useEffect(() => {
        const location = window.location.pathname.split('/')[2];
        if (!location) closeJobDetail();

        const originalReplaceState = window.history.replaceState;

        window.history.replaceState = function () {
            originalReplaceState.apply(this, arguments);
            window.dispatchEvent(new Event('statechange'));
        };

        window.addEventListener('statechange', handleStateChange);

        return () => {
            window.history.replaceState = originalReplaceState;
            window.removeEventListener('statechange', handleStateChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleStateChange]);

    //SCROLL ON CURRENT JOB
    /* useEffect(() => {
        if (currentJob && openedJobDetail) {
            const roomId = document.getElementById('room_' + currentJob._id);
            if (roomId) {
                setTimeout(() => {
                    roomId.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }, 350); // Timeout ensures the DOM has updated
            }
        }
    }, [currentJob, openedJobDetail]); */

    //OPTIONS MENU
    function closeOptionsMenu(room) {
        const els = document.getElementsByClassName('optionsMenu');
        [].forEach.call(els, function (el) {
            if (el.id !== `optionsMenu_${room._id}`) {
                el.classList.add('hidden');
            }
        });
    }

    //DISABLE SCROLL BODY ON OPENEDJOBDETAIL
    /*  useEffect(() => {
        if (openedJobDetail) document.querySelector('body').style.overflow = 'hidden';
        else document.querySelector('body').style.overflow = 'auto';
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentJob, openedJobDetail]); */

    function handleOptionsMenu(room) {
        closeOptionsMenu(room);
        document.getElementById(`optionsMenu_${room._id}`).classList.toggle('hidden');
    }

    //ANIMATIONS
    const openSlideAnimationJobDetailMenu = useSpring({
        transform: openedJobDetail ? 'translateX(0%)' : 'translateX(100%)',
        config: {
            duration: 140,
        },
    });

    useEffect(() => {
        if (store.get('openJobDetail') !== null && currentJob) {
            setOpenedJobDetail(store.get('openJobDetail').open ? true : false);
        } else {
            setOpenedJobDetail(false);
        }
        //eslint-disable-next-line
    }, [store]);

    function handleBookmark(jobId) {
        if (authState.isLogged) {
            postCounter({ type: 'bookmark_job', to: jobId }).then(() => {
                jobRoom.refetch();
                props.refetchFav();
            });
        } else document.getElementById('button-login')?.click();
    }

    const handleOpenLogin = useCallback(() => {
        document.getElementById('button-login')?.click();
    }, []);

    return (
        <>
            {/* store.get('openJobDetail') && store.get('openJobDetail')?.jobId === currentJob?._id */}
            {openedJobDetail && (
                <>
                    <SEO
                        title={jobRoom?.data?.poleEmploi?.title ? jobRoom?.data?.poleEmploi?.title : formatTitle(jobRoom?.data?.description, 60)}
                        description={jobRoom?.data?.description}
                        name={jobRoom?.data?.poleEmploi?.company?.nom ? jobRoom?.data?.poleEmploi?.company?.nom : jobRoom?.data?.user?.profile?.name}
                        type="summary_large_image"
                        image={jobRoom.data?.medias?.length > 0 ? jobRoom.data?.medias[0].url : null}
                    />
                    <animated.div
                        style={openSlideAnimationJobDetailMenu}
                        onClick={(e) => {
                            e?.stopPropagation();
                        }}
                        className={`fixed right-0 z-[30] rounded-none md:rounded-l-3xl flex flex-col bg-white w-full md:w-7/12 lg:w-7/12 h-full shadow-2xl shadow-blueMain/60`}
                    >
                        <div className="flex py-4">
                            <button
                                onClick={() => {
                                    /*  setOpenedJobDetail(false); */
                                    closeJobDetail();
                                }}
                                className="px-2 pb-1"
                            >
                                <XIcon className="w-6 h-6" />
                            </button>
                        </div>

                        <div className="w-full h-full overflow-auto bg-blancSkills pt-2">
                            <div className="max-w-[55rem] px-12 pb-12">
                                {jobRoom?.data?.type === 'job' && jobRoom?.data?.poleEmploi?.refId ? (
                                    <>
                                        <PoleEmploiHeadRoom room={jobRoom} share={false} jobFav={false} />
                                        <PoleEmploiJobRoom room={jobRoom} showJobApply={false} />
                                    </>
                                ) : (
                                    <>
                                        <div className="sm:pb-4">
                                            <HeadRoom room={jobRoom} share={false} jobFav={false} />
                                        </div>
                                        {jobRoom?.data?.type === 'job' && <JobRoom room={jobRoom} />}
                                    </>
                                )}
                                <div className="fixed md:relative -mx-12 px-2 md:px-0 md:mx-0 py-2 w-full md:w-auto md:pb-0 bg-white md:bg-transparent bottom-0 z-10">
                                    <AnswerForm
                                        item={jobRoom.data}
                                        refetch={jobRoom.refetch}
                                        type={'room'}
                                        options={{ placeholder: jobRoom?.data?.type === 'job' ? 'Réagissez à cette offre' : null }}
                                    />
                                </div>
                                <div className="sm:mb-20">
                                    {jobRoom.data?.answers && <Answers item={jobRoom.data} refetch={jobRoom.refetch} /* counters={counters} */ type={'room'} />}
                                </div>
                            </div>
                        </div>
                        <div className="h-36 bg-blancSkills rounded-bl-3xl shadow-2xl">
                            <div className="w-full h-full bg-white rounded-l-3xl pl-12 pr-2 shadow-2xl">
                                {
                                    <>
                                        <div className="w-full h-full flex flex-row items-center pb-12 md:pb-0 gap-4">
                                            {!isAdmin && (
                                                <div className="flex flex-col justify-start md:justify-center">
                                                    <button
                                                        className={`linkCardApplyJobModal text-sm sm:text-xl shadow-sm shadow-lightBlueSkills hover:shadow-xl hover:shadow-lightBlueSkills transition-shadow duration-400 ${
                                                            isJobApply ? 'active' : ''
                                                        }`}
                                                        onClick={
                                                            jobRoom.data?.user
                                                                ? //go to poleEmploi.url
                                                                  () => {
                                                                      authState.isLogged ? jobApply() : handleOpenLogin();
                                                                  }
                                                                : () =>
                                                                      window.open(
                                                                          jobRoom.data?.poleEmploi?.partenaire?.url
                                                                              ? jobRoom.data?.poleEmploi?.partenaire?.url
                                                                              : jobRoom.data?.poleEmploi?.url,
                                                                          '_blank'
                                                                      )
                                                        }
                                                    >
                                                        {!isJobApply ? jobRoom.data?.user ? <>Postuler avec votre profil</> : <>Postuler</> : <>J'ai postulé</>}
                                                    </button>
                                                </div>
                                            )}
                                            <div className="flex flex-col justify-start md:justify-center">
                                                <button
                                                    onClick={() => {
                                                        !authState.isLogged ? handleOpenLogin() : handleBookmark(jobRoom.data._id);
                                                    }}
                                                >
                                                    <HeartIcon className={`w-8 h-8 ${hasBookmark ? 'text-red' : 'text-graySkills'} hover:text-blueMain`} />
                                                </button>
                                            </div>
                                            <div className="flex flex-col justify-start md:justify-center">
                                                <button
                                                    onClick={() => {
                                                        !authState.isLogged && handleOpenLogin();
                                                    }}
                                                >
                                                    <ShareIcon className={`w-8 h-8 text-graySkills hover:text-blueMain`} />
                                                </button>
                                            </div>
                                            <div className="flex flex-col justify-start md:justify-center footerCardFrontRoom">
                                                <button className="flex" onClick={() => handleOptionsMenu(jobRoom.data)}>
                                                    <DotsVerticalIcon className="w-8 sm:h-8 hover:text-blueMain" />
                                                </button>
                                                <OptionsMenu room={jobRoom.data} refresh={jobRoom.refetch} type="_room" />
                                            </div>

                                            {/*  {isAdmin && listUsersJobApply?.length > 0 && (
                                            <div>
                                                <button className={`linkCardApplyJob purple mb-4`} onClick={() => setModalList(true)}>
                                                    Consulter la liste des réponses à votre offre ( {listUsersJobApply?.length} )
                                                </button>
                                            </div>
                                        )} */}
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </animated.div>
                </>
            )}
        </>
    );
}

export default JobDetail;
